input[type=submit] {
	color: $html-background-color;
    text-decoration: none !important;
    font-weight: bold;
	padding: 20px 30px;
	border-radius: 3px;
	transition: 0.2s ease-in-out;
	border: 3px solid $html-background-color;
	background: #fff;
	cursor: pointer;
}

input[type=submit]:hover {
	background: #e3e3e3;
	color: $html-background-color;
}

input[type=submit]:active {
	opacity: 1;
	background: #e3e3e3;
	color: $html-background-color;
	transform: translateY(1px);
}

textarea,
input,
button,
select {
	font-family: inherit;
	font-size: inherit;
}

input[type=submit] {
	margin: 20px 0 0 0;
}

input:not([type="radio"]),
textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
}

textarea {
	resize: vertical;
	height: 250px;
}

input,
textarea {
	padding: 20px;
	font-size: 1.2em;
	border-radius: 3px;
}

input,
textarea {
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	outline: none;
	border: 1px solid #DDDDDD;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus {
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
	border: 1px solid rgba(81, 203, 238, 1);
}
