.container,
.text-container {
    margin: 0 auto;
    position: relative;
    padding: 0 10px;
}

.text-container {
    max-width: 1000px;
}

.container {
    max-width: 1000px;
}

header {
    color: #fff;
    padding: 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    a {
        color: #fff;
        text-decoration: none;
        z-index: 1;
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }

    a.link {
        color: $brand-color;
    }

    .company-name {
        font-size: 1.7em;
    }
}

.content {
    background: #fff;
    padding: 1px 0 0 0;
    position: relative;
}

.screenshot {
    height: auto;
    display: block;
    margin: 0 auto;
    box-shadow: 0 1px 0 #ccc, 0 1px 1px #eee;
    border-radius: 2px;
    padding: 20px 0 0;
    background: url($screenshot-buttons) 4px 4px no-repeat #DDD;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

section {
    padding: 0 0 0 0;
    background: #fff;
    margin-top: 0px;

    &.diagonal {
        -webkit-transform: skewY(-$section-rotation);
        -moz-transform: skewY(-$section-rotation);
        -ms-transform: skewY(-$section-rotation);
        transform: skewY(-$section-rotation);

        >div {
            -webkit-transform: skewY($section-rotation);
            -moz-transform: skewY($section-rotation);
            -ms-transform: skewY($section-rotation);
            transform: skewY($section-rotation);
        }
    }

    &.green {
        color: #ffffff;
        background: $brand-color;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 20px;

        h2,
        h3,
        p {
            max-width: 100%;
            color: #ffffff;
        }

        li {
            font-size: 20px;
        }
    }

    &.white {
        color: #000;
        background: #eee;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 20px;

        h2,
        h3,
        p {
            max-width: 100%;
            color: #000;
        }

        li {
            font-size: 20px;
        }
    }

    &.top {
        color: #000;
        background: #eee;
        text-align: left;
        margin-top: -100px;
        padding-top: 250px;
        padding-bottom: 20px;

        h2,
        h3,
        p {
            max-width: 100%;
            color: #000;
        }
    }

    &.grey {
        color: #000;
        background: #e3e3e3;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 20px;

        h2,
        h3,
        p {
            max-width: 100%;
            color: #000;
        }

        li {
            color: #000
        }
    }
}

.button {
    padding: 13px 18px;
    border-radius: 3px;
    display: inline-block;
    color: $html-background-color;
    cursor: pointer;
    margin: 20px 0 0 0;
    border: 3px solid $html-background-color;
    background: #fff;
    text-decoration: none !important;
    text-align: center;

    &:hover {
        background: rgba(255, 255, 255, 0.3);
        color: $html-background-color;
    }

    &:active {
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
        color: $html-background-color;
        transform: translateY(1px);
    }
}

.logo {
    width: 225px;


    @media #{$mid-point} {
        width: 350px;
    }
}

.halves {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;

    >div {
        flex: 1 0 100%;
    }

    >div>input[type="radio"] {
        flex: none;
    }

    @media #{$mid-point} {
        >div {
            flex: 1;
        }

        >div+div {
            padding-left: 20px;
            margin: 0;
        }
    }
}

.aligned-top {
    align-items: flex-start;

    div {
        text-align: center;
    }
}

.image-grid {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: wrap;
    text-align: center;
    margin: 0 -20px;

    li {
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin: 20px;
        border-radius: 5px;
        text-align: left;
        border: 1px solid $brand-color;
        line-height: 0;

        @media #{$tablet} {
            flex: 1 1 50%;
        }

        @media #{$desktop} {
            flex: 1 1 30%;
        }

        img {
            min-width: 100%;
        }

        >a:hover {
            opacity: 0.9;
        }
    }

    .square-image {
        height: 320px;

        img {
            min-height: 100%;
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .details {
        line-height: 1.2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        padding: 20px;
    }

    .name {
        font-size: 1.3rem;
        color: $brand-color;
    }

    .position {
        margin-top: 5px;
        color: #fff;
    }

    .filler {
        border: 0;
    }
}

.image-list,
.image-list li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.three-columns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    @media #{$mid-point} {
        flex-direction: row;
    }
}

.column {
    flex: 1;
    padding: 10px;
    text-align: center;
}

.bottom-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;

    p,
    a {
        font-size: 14px;
    }
}