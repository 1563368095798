$pattern: "/images/pattern.png";
$screenshot-buttons: "/images/screenshot-buttons.svg";

$brand-color: #6c90dc;
$html-background-color: #3a3a3a;
$secondary-brand-color: #ffffff;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";

$section-rotation: 5deg;

@import "elements";
@import "layout";
@import "contact";
@import "blog";
@import "forms";
@import "navigation";
@import "footer";
@import "cloudcannon";